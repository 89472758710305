import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

export const Inicio = () => {
  return (
    <div className='home'>
      <h1>
        Hola, soy <strong>Nicolas Quiroga</strong> y soy desarrollador front-end. Me apasiona el <strong>diseño web</strong> y la <strong>programación</strong>.
      </h1>
      
      <div className="email-container">
        <FontAwesomeIcon icon={faEnvelope} className='custom-icon' bounce color='white'/>
        <div className="emailtext-parrafo">
          <p>nquirogawebdev@gmail.com</p>
        </div>
      </div>
      
    </div>
  );
};
