import React from 'react'

export const Footer = () => {
  return (
    <footer className='footer'>
      
        Portafolio Nicolas Quiroga WEB &copy; 
    </footer>
  )
}
