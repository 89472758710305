import React from 'react';
import phpImage from '../images/php.png';
import htmlImage from '../images/html.png';
import cssImage from '../images/css.png';
import javascriptImage from '../images/javascript.png';
import mysqlImage from '../images/mysql.png';
import reactImage from '../images/reactimg.png';
import mongodbImage from '../images/mongodb.png';

import '../Servicios.css'; // Archivo de estilos CSS para Servicios



export const Servicios = () => {
  return (
    <div className='page'>
      <h1 className='heading'>Stack</h1>
      
      <section className='services'>

        <article className='service'>
          <img src={htmlImage} alt="HTML Logo" className="img-service" />
        </article>
        <article className='service'>
          <img src={cssImage} alt="CSS Logo" className="img-service" />
        </article>
        <article className='service'>
          <img src={javascriptImage} alt="JS Logo" className="img-service" />
        </article>
        <article className='service'>
          <img src={reactImage} alt="react logo" className="img-service" />
        </article>
        <article className='service'>
          <img src={mongodbImage} alt="mongodb logo" className="img-service" />
        </article>



        <article className='service'>
          <img src={phpImage} alt="PHP Logo" className="img-service" />
        </article>
        <article className='service'>
          <img src={mysqlImage} alt="mysql Logo" className="img-service" />
        </article>

     
    
      </section>
    </div>
  );
};
