import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import {faPhone} from '@fortawesome/free-solid-svg-icons';

export const Contacto = () => {
  return (
    <div className='page'>

    <h1 className='heading'>Contacto</h1>
    
    
    <div className="email-container">
        <FontAwesomeIcon icon={faEnvelope} className='custom-icon-page' bounce color='white'/>
        <div className="emailtext-parrafo">
          <p>nquirogawebdev@gmail.com</p>
        </div>
      </div>

      <div className='phone-conteiner'>
      <FontAwesomeIcon icon={faPhone} className='custom-phone' bounce color='white' />
      <div className='phone-number'>
        
      <p>+54 26 5726 3845</p>

      </div>
      
      </div>

  </div>
  )
}
