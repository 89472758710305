import React from 'react';
import curriculumnicolasquirogawebdev from '../images/curriculumnicolasquirogawebdev.jpg';

export const Curriculum = () => {
  return (
    <div className='page'>
      <h1 className='heading'>Currículum</h1>
      <img 
        src={curriculumnicolasquirogawebdev} 
        alt="Currículum de Nicolás Quiroga, Desarrollador Web" 
        className="img-curriculum" 
      />
      <p>Descarga mi currículum en formato PDF:</p>
      <a 
        href="/pdf/CurrículumVitaeNicolasQuirogaDesarrolloWeb.pdf" 
        download="CurrículumVitaeNicolasQuirogaDesarrolloWeb.pdf"
        className="descargar-curriculum"
      >
        Descargar Currículum
      </a> 

      <h2>Certificados</h2>
      <ul className="certificados-lista">
        <li><a href="/pdf/programadorwebinicial.pdf" download="programadorwebinicial.pdf">Programador web inicial</a></li>
        <li><a href="/pdf/phpmysql.pdf" download="phpmysql.pdf">Programación web con PHP y MYSQL</a></li>
        <li><a href="/pdf/phpmysqlavanzado.pdf" download="phpmysqlavanzado.pdf">Programación web con PHP y MYSQL avanzado</a></li>
        <li><a href="/pdf/diplomatura.pdf" download="diplomatura.pdf">Diplomatura en programación web fullstack</a></li>
        <li><a href="/pdf/masterreact.pdf" download="masterreact.pdf">Master en React: Aprender ReactJS, Hooks, MERN, Nodejs, JWT+</a></li>
      </ul>
    </div>
  );
};
