import React from 'react';
import huweb from '../images/huweb.png'; // Asegúrate de usar la imagen optimizada

export const Portafolio = () => {
  return (
    <div className='page'>
      <p className='p-portafolio'>HU AGENCIA CREATIVA</p>
      <a href="https://huweb.42web.io/" target="_blank" rel="noopener noreferrer">
        <img 
          src={huweb} 
          alt="Hu Agencia Creativa" 
          className="img-huweb" 
          loading="lazy" // Lazy loading
        />
      </a>
      <p className='p-portafolio'>HTML, CSS, JS, REACT</p>
    </div>
  );
};
