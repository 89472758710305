import './App.css';
import { MisRutas } from './router/MisRutas';

import {NextUIProvider} from "@nextui-org/react";

function App() {
  return (
    <div className='layout'>
     
      <NextUIProvider>
      <MisRutas/>
    </NextUIProvider>
    
    </div>
  );
}

export default App;
